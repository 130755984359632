import { Injectable } from '@angular/core';
import {
	DeleteRequest,
	EntityGetRequest,
	FeedbackRequest,
	FeedbackTypeItem,
	InternalUserItem,
	SuggestionChatRequest,
	SuggestionItem,
	SuggestionRequest,
	SuggestionSummaryRequest,
} from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { Subject, lastValueFrom } from 'rxjs';
import { ApiService } from '../../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/services';
import { ResultHelper } from '../../../../../../../../goldstar-share/src/app/common/result-extension';
import { Result } from '../../../../../../../../goldstar-share/src/app/models/models';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class FeedbackService {
	public feedbackTypeList: FeedbackTypeItem[] = [];

	updatedFeedbackItem = new Subject<FeedbackRequest>();
	showMySuggestionCount: boolean = false;
	showSuggestionReceivedCount: boolean = false;
	showArchivedSuggestionCount: boolean = false;

	suggestionReceivedList!: string[];
	mySuggestionsList!: string[];
	archivedMySuggestionsList!: string[];
	archivedReceivedSuggestionsList!: string[];

	constructor(private apiV2: ApiService) {}

	public async fetchAllFeedbackTypes(): Promise<Result<FeedbackTypeItem[]>> {
		if (this.feedbackTypeList.length == 0) {
			return await lastValueFrom(this.apiV2.getFeedbackType()).then((response) => {
				if (response.isSuccess) {
					this.feedbackTypeList = response.data?.items ?? [];
					return ResultHelper.successResponse(response.data?.items ?? []);
				} else {
					return ResultHelper.failedResponse<FeedbackTypeItem[]>('Failed to fetch feedback type.');
				}
			});
		}
		return ResultHelper.successResponse(this.feedbackTypeList ?? []);
	}

	public async saveFeedback(feedback: FeedbackRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.feedbackAdd({ body: feedback }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	/**
	 * Deletes a particular feedback from database
	 * @param request
	 * @returns
	 */
	public async deleteFeedback(request: DeleteRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.feedbackDelete({ body: request })).then((deleteResponse) => {
			if (deleteResponse.isSuccess) {
				return ResultHelper.successResponse('Successfully deleted Feedback');
			} else {
				return ResultHelper.failedResponse('Failed to delete Feedback');
			}
		});
	}

	//method for handling whitespace
	trimValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (control.value) {
				let trimmedValue = control.value.trim();
				if (trimmedValue === '') {
					return { required: true };
				} else {
					return null;
				}
			}
			return null;
		};
	}

	public async saveSuggestion(suggestion: SuggestionRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.suggestionAdd({ body: suggestion }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async saveSuggestionReply(suggestion: SuggestionRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.suggestionReplyAdd({ body: suggestion }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async updateReplyViewed(suggestion: SuggestionRequest): Promise<Result<string>> {
		return await lastValueFrom(this.apiV2.updateSuggestionReplyView({ body: suggestion }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data ?? '');
				}
				throw 'Failed to process save request';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async fetchSuggestionReplies(request: SuggestionChatRequest): Promise<Result<SuggestionItem[]>> {
		return await lastValueFrom(this.apiV2.fetchSuggestionReplies({ body: request }))
			.then(async (response) => {
				if (response.isSuccess) {
					return ResultHelper.successResponse(response.data?.items ?? []);
				}
				throw 'Failed to fetch replies.';
			})
			.catch((error) => {
				return ResultHelper.failedResponse(error);
			});
	}

	public async fetchAllSuggestion(currentUser: InternalUserItem): Promise<Result<boolean>> {
		const entityRequest: EntityGetRequest = {
			searchFilter: [{ searchOption: 'ALL' }],
			pageSize: 1000,
			pageNumber: 1,
		};
		let suggestionRequest: SuggestionSummaryRequest = {
			entityGetRequest: entityRequest,
			userGUID: currentUser.internalUserGUID,
		};
		try {
			const response = await lastValueFrom(this.apiV2.viewSuggestionCount({ body: suggestionRequest }));

			if (response.isSuccess) {
				let suggestionSummaryDataList = response.data ?? {};
				// suggestionDataList = suggestionDataList.filter((y) => !y.parentSuggestionGUID) ?? [];

				// const sentToMe = suggestionDataList.filter(
				// 	(suggestion) =>
				// 		suggestion.parentSuggestionGUID === null &&
				// 		(suggestion.childReceiverInternalUserGUID
				// 			? suggestion.childReceiverInternalUserGUID === currentUser.internalUserGUID
				// 			: suggestion.receiverInternalUserGUID === currentUser.internalUserGUID) &&
				// 		suggestion.senderInternalUserGUID !== currentUser.internalUserGUID // Ensure the logged-in user is not the original sender
				// );

				// this.suggestionReceivedList = sentToMe.filter((suggestion) => !suggestion.replyViewedYN && suggestion.receiverArchivedYN != 'Y');

				// // "My Suggestions" section: Check for new replies
				// this.mySuggestionsList = suggestionDataList.filter(
				// 	(suggestion) =>
				// 		suggestion.senderInternalUserGUID === currentUser.internalUserGUID &&
				// 		(suggestion.childSuggestionGUID
				// 			? suggestion.childReceiverInternalUserGUID === suggestion.senderInternalUserGUID
				// 			: suggestion.senderInternalUserGUID !== currentUser.internalUserGUID) &&
				// 		!suggestion.replyViewedYN &&
				// 		suggestion.senderArchivedYN != 'Y'
				// );
				// const mySuggestionsArchived = suggestionDataList.filter((suggestion) => suggestion.senderInternalUserGUID === currentUser.internalUserGUID && suggestion.senderArchivedYN == 'Y');

				// this.archivedMySuggestionsList = mySuggestionsArchived.filter((suggestion) => !suggestion.replyViewedYN && suggestion.replyContent);

				// // "Sent to Me Archived": suggestions archived by the receiver
				// const sentToMeArchived = suggestionDataList.filter(
				// 	(suggestion) => suggestion.parentSuggestionGUID === null && suggestion.receiverInternalUserGUID == currentUser.internalUserGUID && suggestion.receiverArchivedYN === 'Y'
				// );

				// this.archivedReceivedSuggestionsList = sentToMeArchived.filter((suggestion) => !suggestion.replyViewedYN);

				this.suggestionReceivedList = suggestionSummaryDataList?.suggestionReceivedList ?? [];
				this.mySuggestionsList = suggestionSummaryDataList?.mySuggestionsList ?? [];
				this.archivedMySuggestionsList = suggestionSummaryDataList?.archivedMySuggestionsList ?? [];
				this.archivedReceivedSuggestionsList = suggestionSummaryDataList?.archivedReceivedSuggestionsList ?? [];

				this.showMySuggestionCount = this.mySuggestionsList.length > 0;
				this.showSuggestionReceivedCount = this.suggestionReceivedList.length > 0;
				this.showArchivedSuggestionCount = this.archivedReceivedSuggestionsList.length > 0 || this.archivedMySuggestionsList.length > 0;

				return ResultHelper.successResponse(this.showMySuggestionCount || this.showSuggestionReceivedCount || this.showArchivedSuggestionCount);
			} else {
				// Handle the case where the response is not successful
				return ResultHelper.successResponse(false);
			}
		} catch (error) {
			// Catch any errors and return a failed result
			return ResultHelper.successResponse(false);
		}
	}
}
